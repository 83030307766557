import * as React from "react";
import Meta from "../components/shared/Meta";
import Layout from "../components/shared/Layout";
import MyStory from "../components/mystory/MyStory";
import QuestionsAndAnswers from "../components/questionsandanwsers/QuestionsAndAnswers";

const About = () => {
    return (
        <Layout>
            <Meta title="About Me"/>
            <MyStory/>
            <QuestionsAndAnswers/>
        </Layout>
    )
}

export default About;