export const QAData = [
    {
        question: "How long have I been a developer?",
        answer: "Since Oct 2018."
    },
    {
        question: "Which technology am I most confident in?",
        answer: "ReactJS."
    },
    {
        question: "How can I be contacted?",
        answer: "I usually reply to emails pretty quickly."
    }
]