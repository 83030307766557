import React from "react";
import * as styles from "./MyStory.module.css";

export default function MyStory() {
    return (
        <div className={styles.myStoryWrapper}>
            <div className={styles.intro}>
                Hello, I'm Nhan!
            </div>
            <div className={styles.paragraph}>
                I started out as a business student at the <b>University of Western Australia</b>. Having spent 2 years
                in <b>Perth</b>, I returned to Vietnam to complete my <b>Bachelor of Commerce</b> at the Royal Melbourne
                Institute of Technology, Vietnam. Shortly afterward, I went to Japan for my <b>Master Degree in
                Economics</b> at <b>Saitama University</b>.<br/>
                Looking back, I didn't know that management wasn't the right choice for me, but I'm glad that I took the
                time to complete these degrees. They have certainly given me a new perspective in my jobs after
                graduation;
                a perspective that I'm not sure if I could have possibly gained should I have started out with an
                Engineering degree instead. Living overseas have also given me an opportunity to be somewhat fluent
                in <b>English</b> and <b>Japanese</b> with degrees to back (<b>IELTS 8.0, TOEIC 980</b> & <b>JLPT N3</b>).
            </div>
            <div className={styles.paragraph}>
                Upon returning to Vietnam after graduation, I was offered a job as a <b>Sales Engineer</b> at <b>Keyence
                Vietnam</b>. The job paid well, but I have soon realized that this job wasn't for me. Nevertheless, I
                persevered for a little over a year at this job.
            </div>
            <div className={styles.paragraph}>
                My passion for programming began to blossom during that time, when I took a <b>C# bootcamp</b> to get
                away from
                my job. I didn't like C# all that much - I still don't - but I <b>LOVE</b> programming. I love creating
                things, I love putting together logic to solve new and challenging problems. But most importantly, I
                love creating websites that are <b>beautiful, usable & engaging</b>. I started reading books, ranging
                from basic programming knowledge such as <b>Data Structures, Algorithms, Design Patterns</b> to more
                Frontend-specific books such as the excellent <b>You don't know JS</b> by Kyle Simpson, more academy
                books such as <b>About Face</b> were also sought after.
            </div>
            <div className={styles.paragraph}>
                Then, I was offered my first <b>Developer</b> position at <b>Vitalify Asia</b> in 2018. I took part in
                various projects including <b>Frontend (Angular, React, Vue), Backend (Python, NodeJS), Mobile (Cordova,
                React Native)</b> as well as <b>DevOps</b> roles for deployment on Firebase & AWS services. I was here
                for 1.5 years before I moved on to my current position at <b>Netcompany Vietnam</b>, where I can
                continue to grow my technical and management skills in a more competitive environment.
            </div>
            <div className={styles.paragraph}>
                This is unquestionably the period when I grow the most. I took on more possibility, I learn new
                technology such as <b>Gatsby, NextJS</b>, I aim to take formal certificates from <b>AWS & Azure</b>, and
                I started teaching at <b>KMIN Academy</b>. Being an instructor required me to both utilizing people
                management skills and having an in-depth understanding of the knowledge that I present to the students.
                Things accelerated when I took on a freelance job as both <b>Project manager</b> and <b>Developer</b>.
            </div>
            <div className={styles.paragraph}>
                Future me will continue to pursue this path of acquiring knowledge, supporting others and looking for
                opportunities to change the world through my craft.
            </div>
        </div>
    )
}