import React from "react";
import * as styles from "./QuestionsAndAnswers.module.css";
import {QAData} from "../../data/qa";

export function QAItem({qa, index}) {
    return (
        <div>
            <div className={styles.qaQuestion}>{index + 1}. {qa.question}</div>
            <div className={styles.qaAnswer}>- {qa.answer}</div>
        </div>
    )
}

export default function QuestionsAndAnswers() {
    return (
        <div className={styles.qaWrapper}>
            <div className={styles.title}>Questions and answers</div>
            {QAData.map((qa, i) => <QAItem key={qa.question} qa={qa} index={i}/>)}
        </div>
    )
}